import React from "react";

import { Button, Form } from "reactstrap";
import { Field, Formik } from "formik";
import { useDispatch } from "react-redux";

import { BEM } from "helpers/BEM.helper";
import { PasswordValidationMessages } from "views/components/forms/PasswordValidationMessages";
import { assertPasswordIsValid } from "helpers/inputValidators/text/password";
import { useFetchSelector } from "helpers/hooks/useFetchSelector.hook";
import { FetchInputFormik } from "views/common/forms/fields/FetchInputFormik";
import { authenticationActions } from "slices/authentication/authentication.actions";

const classes = new BEM({
  block: { name: "ResetPasswordForm" },
  elements: [
    { name: "newPassword", extras: [] },
    { name: "confirmNewPassword", extras: [] },
    { name: "submitButton", extras: ["w-100"] },
  ],
});

type ResetPasswordFormErrors = Partial<{
  newPassword: string;
  confirmNewPassword: string;
}>;

export const ResetPasswordForm: React.FC = () => {
  const currentUser = useFetchSelector("currentUser");
  const authentication = useFetchSelector("authentication");
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{
        newPassword: "",
        confirmNewPassword: "",
        email: currentUser?.email,
        session: authentication.tokens?.session,
        userId: currentUser?.userId,
      }}
      validate={(values) => {
        const errors: ResetPasswordFormErrors = {};

        if (!values.newPassword) {
          errors.newPassword = "Required";
        }
        if (!values.confirmNewPassword) {
          errors.confirmNewPassword = "Required";
        }

        if (!assertPasswordIsValid(values.newPassword)) {
          errors.newPassword = "Enter a valid password";
        }

        if (values.newPassword !== values.confirmNewPassword) {
          errors.confirmNewPassword = "Passwords must match.";
        }

        return errors;
      }}
      onSubmit={(values) => {
        if (values.email && values.session) {
          dispatch(
            authenticationActions.postSetPermanentPassword({
              email: values.email,
              password: values.newPassword,
              session: values.session,
            }),
          );
        }
      }}
      isInitialValid={false}
    >
      {(formProps) => {
        return (
          <Form
            className={classes.getBlockClassNames()}
            onSubmit={formProps.handleSubmit}
          >
            <Field
              className={classes.getElementClassNames("newPassword")}
              component={FetchInputFormik}
              label="New Password"
              name="newPassword"
              type="password"
            />
            <Field
              className={classes.getElementClassNames("confirmNewPassword")}
              component={FetchInputFormik}
              label="Confirm New Password"
              name="confirmNewPassword"
              type="password"
            />
            <PasswordValidationMessages
              password={formProps.values.newPassword}
            />
            <Button
              type="submit"
              color="info"
              disabled={!formProps.isValid}
              className={classes.getElementClassNames("submitButton")}
            >
              Submit
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};
