import React from "react";

import { FetchCard } from "views/common/cards/FetchCard";
import { ResetPasswordForm } from "views/components/forms/ResetPasswordForm";

export const ResetPasswordFormCard: React.FC = () => {
  return (
    <FetchCard
      preTitle="Reset Password"
      preTitleSubtext="Please provide a new password."
    >
      <ResetPasswordForm />
    </FetchCard>
  );
};

export default ResetPasswordFormCard;
