import React, { useEffect, useState } from "react";

import { Button, Col, Container, Input, Row, Tooltip } from "reactstrap";

import { BEM } from "helpers/BEM.helper";
import { FetchCard } from "views/common/cards/FetchCard";
import VerifyContactDetailsButtons from "views/components/buttons/VerifyContactDetailsButton";
import { VerifiedTag } from "views/components/atoms/VerificationTags";
import { textTruncate } from "helpers/text-formatting/textTruncate";
import { useFetchSelector } from "helpers/hooks/useFetchSelector.hook";

const classes = new BEM({
  block: {
    extras: ["d-flex", "justify-content-center"],
    name: "VerifyContactOptionsPage",
  },
  elements: [
    { name: "title" },
    { name: "body" },
    { name: "skip" },
    { name: "emailVerified", extras: ["d-flex", "justify-content-between"] },
    { name: "phoneVerified", extras: ["d-flex", "justify-content-between"] },
    { name: "verified", extras: ["d-flex", "justify-content-between"] },
    { name: "unverified" },
    { name: "emailVerify", extras: ["form-group", "form-control", "mt-2"] },
    { name: "phoneVerify", extras: ["form-group", "form-control", "mt-2"] },
    { name: "emailField", extras: ["mt-3"] },
    { name: "phoneField", extras: [""] },
    { name: "emailVerifyBtn", extras: [""] },
    {
      name: "editPhoneNumber",
      extras: ["btn-link", "border-0", "text-primary"],
    },
    { name: "editEmail", extras: ["btn-link", "border-0", "text-primary"] },
    { name: "verifyButton", extras: ["p-3"] },
  ],
});

export interface VerifyContactOptionsPageProps {
  hideVerifyContactOptions: () => void;
}

export const VerifyContactOptionsPage: React.FC<
  VerifyContactOptionsPageProps
> = (props: VerifyContactOptionsPageProps) => {
  const { hideVerifyContactOptions } = props;
  const authentication = useFetchSelector("authentication");
  const currentUser = useFetchSelector("currentUser");
  const email = currentUser ? currentUser.email : "";
  const phone = currentUser ? currentUser.phone : "";

  const isPhoneVerified =
    authentication?.tokens && authentication.tokens.phoneVerified;
  const isEmailVerified =
    authentication?.tokens && authentication.tokens.emailVerified;

  const [emailCodeValue, setEmailCodeValue] = useState("");
  const [phoneCodeValue, setPhoneCodeValue] = useState("");
  const [emailTooltipOpen, setEmailTooltipOpen] = useState(false);
  const emailToggle = () => setEmailTooltipOpen(!emailTooltipOpen);

  useEffect(() => {
    return () => {
      document.body.classList.toggle("verify-contact-options-page");
    };
  });
  useEffect(() => {
    document.body.classList.toggle("verify-contact-options-page");
  });

  return (
    <div className={"beautiful-background"}>
      <Container className={classes.getBlockClassNames()} fluid={true}>
        <Col
          {...{
            lg: "4",
            md: "6",
            sm: "8",
          }}
        >
          <FetchCard
            preTitle={"Contact Confirmation"}
            preTitleSubtext={"Verify your email and phone below."}
          >
            <Row>
              <Col className={classes.getElementClassNames("emailField")}>
                {isEmailVerified ? (
                  <div className="mb-3">
                    Email:
                    <div
                      className={classes.getElementClassNames("emailVerified")}
                      id={"email"}
                    >
                      {email && textTruncate(email, 25)}
                      <Tooltip
                        placement="top"
                        isOpen={emailTooltipOpen}
                        autohide={true}
                        target="email"
                        toggle={emailToggle}
                      >
                        {email}
                      </Tooltip>
                      <VerifiedTag />
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="d-flex">
                      <div id={"email"}>
                        Email: {email && textTruncate(email, 30)}
                        <Tooltip
                          placement="top"
                          isOpen={emailTooltipOpen}
                          autohide={true}
                          target="email"
                          toggle={emailToggle}
                        >
                          {email}
                        </Tooltip>
                      </div>
                    </div>
                    <div className="d-flex mb-3">
                      <Input
                        className={classes.getElementClassNames("emailVerify")}
                        type="text"
                        label="Email"
                        autoComplete="off"
                        placeholder="Enter code here"
                        onChange={(e) => setEmailCodeValue(e.target.value)}
                      />
                      <VerifyContactDetailsButtons
                        className={classes.getElementClassNames("verifyButton")}
                        attributeName="email"
                        code={emailCodeValue}
                      />
                    </div>
                  </>
                )}
              </Col>
            </Row>
            <Row>
              <Col className={classes.getElementClassNames("phoneField")}>
                {isPhoneVerified ? (
                  <div className="mb-3">
                    Phone:
                    <div
                      className={classes.getElementClassNames("phoneVerified")}
                      id={"phone"}
                    >
                      {phone}
                      <VerifiedTag />
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="d-flex ">
                      <div>Phone: {phone}</div>
                    </div>
                    <div className="d-flex">
                      <Input
                        className={classes.getElementClassNames("phoneVerify")}
                        type="text"
                        label="Phone Number"
                        autoComplete="off"
                        placeholder="Enter code here"
                        onChange={(e) => setPhoneCodeValue(e.target.value)}
                      />
                      <VerifyContactDetailsButtons
                        className={classes.getElementClassNames("verifyButton")}
                        attributeName="phone_number"
                        code={phoneCodeValue}
                      />
                    </div>
                  </>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  color="link"
                  className={classes.getElementClassNames("skip")}
                  onClick={() => {
                    hideVerifyContactOptions();
                  }}
                >
                  {isPhoneVerified && isEmailVerified ? "Continue" : "Skip"}
                </Button>
              </Col>
            </Row>
          </FetchCard>
        </Col>
      </Container>
    </div>
  );
};

export default VerifyContactOptionsPage;
