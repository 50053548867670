import { FieldProps, useFormikContext } from "formik";
import React, { ReactNode } from "react";
import { FormGroup } from "reactstrap";
import { InputType } from "reactstrap/lib/Input";

import { FetchFieldLabel } from "views/common/forms/fields/FetchFieldLabel";

function determineLabelPosition(type: string) {
  if (type === "checkbox") {
    return false;
  }
  return true;
}

export type FetchFieldValidationWrapperFormikProps = {
  label?: string | { text: string; tag: string };
  type: InputType;
  children?: ReactNode;
  classNames: { block: string; label: string; error: string };
  ["data-cy"]?: string;
} & FieldProps;

export const FetchFieldValidationWrapperFormik: React.FC<
  FetchFieldValidationWrapperFormikProps
> = (props: FetchFieldValidationWrapperFormikProps) => {
  const {
    label,
    children,
    type,
    classNames: { block, label: labelClassNames, error: errorClassNames },
  } = props;
  const fieldIsDirty = useFieldDirty(props.field.name);
  const fieldError = props.form.errors[props.field.name];
  const fieldHasError = !!fieldError;
  const fieldIsTouched = !!props.form.touched[props.field.name];
  const displayAsErrored = (fieldIsDirty || fieldIsTouched) && fieldHasError;

  const shouldAppendDangerClass: boolean = displayAsErrored;
  const labelExists = !!label;
  const labelFirst = determineLabelPosition(type);
  const blockClassNames: string = shouldAppendDangerClass
    ? block + " has-danger"
    : block;
  const Label: JSX.Element | null = labelExists ? (
    <FetchFieldLabel {...{ label, className: labelClassNames }} />
  ) : null;
  const shouldShowLabelBefore: boolean = labelExists && labelFirst;
  const shouldShowLabelAfter: boolean = labelExists && !labelFirst;

  return (
    <FormGroup
      className={blockClassNames}
      style={{ width: "100%" }}
      data-cy={props["data-cy"]}
    >
      {shouldShowLabelBefore && Label}
      {children}
      {shouldShowLabelAfter && Label}
      {displayAsErrored && (
        <label className={errorClassNames + " error" + " fds-text-red-500"}>
          {props.form.errors[props.field.name]}
        </label>
      )}
    </FormGroup>
  );
};

const useFieldDirty = <Values extends Record<string, unknown>>(
  fieldName: keyof Values,
): boolean => {
  const { values, initialValues } = useFormikContext<Values>();
  return values[fieldName] !== initialValues[fieldName];
};
